import Routes from "./routes";
import {MsalProvider} from '@azure/msal-react'

function App({msalInstance}) {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="-mt-36 lg:w-[50%] lg:h-[90%] xl:w-[35%] md:w-full md:h-[92%] w-full h-full bg-black overflow-y-auto">
        <Routes/>
      </div>
    </MsalProvider>
  );
}

export default App;